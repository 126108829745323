<template>
  <Fieldset legend="แบบฟอร์มแก้ไขข้อมูลส่วนตัว">
    <form @submit.prevent="saveValidatedForm" id="validity">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <label for="wight">User Name สำหรับ Login</label>
          <div class="p-formgroup-inline">
            <InputText
              id="user_name"
              type="text"
              v-model="forms.userName"
              disabled
              readonly
            />
            <InlineMessage v-if="validates['userName']">ห้ามว่าง</InlineMessage>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">ระดับ User</label>
          <Dropdown
            id="userLevel"
            v-model="forms.userLevel"
            :options="levels"
            optionLabel="name"
            optionValue="code"
            placeholder="ระดับ User (AC,AL,OL)"
            :class="[validates['userLevel']]"
            :disabled="true"
          />
          <!-- :disabled="userLevel == 'OL'" -->

          <InlineMessage v-if="validates['userLevel']">ห้ามว่าง</InlineMessage>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="areaId">พื้นที่</label>
          <Dropdown
            id="areaId"
            v-model="forms.areaId"
            :options="$store.state.area.areas"
            optionLabel="areaName"
            optionValue="codeId"
            placeholder="พื้นที่"
            :class="[validates['areaId'] && !isDiabledArea]"
            :filter="true"
            :disabled="isDiabledArea"
          />
          <InlineMessage v-if="validates['areaId'] && !isDiabledArea"
            >ห้ามว่าง</InlineMessage
          >
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="fname">ชื่อ</label>
          <div class="p-formgroup-inline">
            <InputText
              id="fname"
              type="text"
              v-model="forms.fname"
              placeholder="ชื่อ"
              :class="[validates['fname']]"
              :required="true"
            />
            <InlineMessage v-if="validates['fname']">ห้ามว่าง</InlineMessage>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="lname">นามสกุล</label>
          <div class="p-formgroup-inline">
            <InputText
              id="lname"
              type="text"
              v-model="forms.lname"
              placeholder="นามสกุล"
              :class="[validates['lname']]"
              :required="true"
            />
            <InlineMessage v-if="validates['lname']">ห้ามว่าง</InlineMessage>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="email">อีเมล</label>
          <div class="p-formgroup-inline">
            <InputText
              id="email"
              type="email"
              v-model="forms.email"
              placeholder="อีเมล"
              :class="[validates['email']]"
              :required="true"
            />
            <InlineMessage v-if="validates['email']">ห้ามว่าง</InlineMessage>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="wight">หมายเลขโทรศัพท์มือถือ</label>
          <div class="p-formgroup-inline">
            <InputMask
              id="mobile"
              v-model="forms.mobile"
              :modelValue="forms.mobile"
              mask="9999999999"
              placeholder="หมายเลขโทรศัพท์มือถือ"
              :class="[validates['mobile']]"
              :required="true"
            />
            <InlineMessage v-if="validates['mobile']">ห้ามว่าง</InlineMessage>
          </div>
        </div>

        <!-- หมายเลขโทรศัพท์บ้าน/ที่ทำงาน -->
        <div class="p-field p-col-12 p-md-4">
          <label for="tel">หมายเลขโทรศัพท์บ้าน/ที่ทำงาน</label>
          <div class="p-formgroup-inline">
            <InputMask
              id="tel"
              v-model="forms.tel"
              :modelValue="forms.tel"
              mask="999999999"
              placeholder="หมายเลขโทรศัพท์"
            />
          </div>
        </div>
        <!--/ หมายเลขโทรศัพท์บ้าน/ที่ทำงาน -->
      </div>
      <div class="p-d-flex p-jc-center">
        <Button
          label="บันทึกการแก้ไขข้อมูล"
          type="submit"
          icon="pi pi-save"
          class="p-button-success"
        />
        <Button
          label="ไม่ทำรายการ"
          type="button"
          icon="pi pi-arrow-left"
          class="p-button-link"
          @click="() => $router.back(-1)"
        />
      </div>
    </form>
  </Fieldset>
</template>
<script>
import { initCustomValidity } from '@/HTMLElement'
export default {
  name: 'changeProfile',
  layout: 'Private',
  data() {
    return {
      dialog: {
        isOpen: false,
        password: null
      },
      forms: {
        userPassword: '',
        codeId: '',
        fname: '',
        lname: '',
        mobile: '',
        tel: '',
        email: '',
        userStatus: '',
        userLevel: '',
        areaId: '',
        userName: '',
        area: {
          codeId: null
        }
      },
      areas: []
    }
  },
  watch: {
    'forms.userLevel'(newV, oldV) {
      //console.log('newV ::==', newV)
      if ('AC' == newV) {
        this.forms.areaId = null
      } else {
        const area = this.forms.area
        this.forms.areaId = area?.codeId
      }
    }
  },
  computed: {
    validates() {
      const fields = Object.keys(this.forms).reduce((fields, value) => {
        const _value = this.forms[[value]]
        //console.log("_value :==", _value);
        //console.log("this.forms[value] :==", this.forms[value]);
        return {
          ...fields,
          [value]: _value != null && _value != '' ? '' : 'p-invalid'
        }
      }, {})
      console.log('fields ::==', fields)
      return fields
    },
    userLevel() {
      const { codeId, userLevel: level } = this.$store.getters['oauth/session']
      return level
    },
    levels() {
      const { codeId, userLevel: level } = this.$store.getters['oauth/session']
      if (codeId) {
        const { levels } = this.$store.state.constance
        return levels.filter((ul, i) => {
          const { code, name } = ul
          return level == 'AC' || (level != 'AC' && ['AL', 'OL'].includes(code))
        })
      } else {
        return []
      }
    },
    isDiabledArea() {
      return (
        this.userLevel != 'AC' ||
        (this.userLevel == 'AC' && this.forms.userLevel == 'AC')
      )
    }
  },
  mounted() {
    this.$nextTick(async () => {
      const user = this.$store.getters['oauth/session']
      //console.log('user ::==', user)
      if (user.codeId == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail: 'ระบบไม่สามารถให้บริการได้ กรุณาติดต่อเจ้าหน้าที่ดูแล',
          life: 3000
        })
      } else {
        this.forms = {
          ...user,
          areaId: user?.area?.codeId
        }
        console.log(' this.forms  ::==' + JSON.stringify(this.forms))
        await this.$store.dispatch('area/fetchAreasOrderNameTh')
      }
      initCustomValidity('validity')
    })
  },
  methods: {
    async saveValidatedForm() {
      //confirm before  save
      this.$confirm.require({
        ...this.$primevue.config.locale,
        reject: async () => {
          const { areaId } = this.forms

          let payload = {
            ...this.forms,
            area: {
              codeId: areaId
            }
          }
          if (areaId == null) {
            payload['area'] = null
          }

          const {
            status,
            code,
            invalidCode,
            data
          } = await this.$store.dispatch('user/updateUserProfile', payload)
          console.log('status ::==' + JSON.stringify(status))
          console.log('data ::==' + JSON.stringify(data))
          console.log('code ::==' + JSON.stringify(code))
          if (code == 200) {
            localStorage.setItem('user', JSON.stringify(data))
            this.$toast.add({
              severity: 'success',
              summary: 'สถานะการทำรายการ ',
              detail: 'แก้ไขข้อมูลส่วนตัวของคุณ สำเร็จ',
              life: 3000
            })
            this.dialog.isOpen = false
            this.dialog.password = null
          } else {
            /*
             * 101 = profile not found
             * 102 = password incorrect
             * 103 = ?
             */
            let message =
              'แก้ไขข้อมูลส่วนตัวของคุณ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ'
            if ('101' == invalidCode) {
              message = 'ไม่พบข้อมูลของคุณในระบบ'
            } else if ('102' == invalidCode) {
              message = 'รหัสผ่านไม่ถูกต้อง'
            }
            this.$toast.add({
              severity: 'error',
              summary: 'สถานะการทำรายการ',
              detail: message,
              life: 3000
            })
          }
        }
      })
    }
  }
}
</script>
 